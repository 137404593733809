import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffffff"
        }
    },
    status: {
        danger: "orange",
    },
});

export default theme;
