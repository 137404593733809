// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-audio-player {
  width: 74%;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  margin-left: 500px;
}

.rhap_progress-bar {
  display: None;
}

.rhap_player .rhap_time.rhap_totalTime {
  display: none !important;
}

.rhap_time-color {
  color: white;
}

button.rhap_play-pause-button {
  font-size: 44px;
  /* Adjust the font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,mCAAmC;AACrC","sourcesContent":[".custom-audio-player {\n  width: 74%;\n  background-color: #f0f0f0;\n  padding: 10px;\n  border-radius: 5px;\n  align-items: center;\n  margin-left: 500px;\n}\n\n.rhap_progress-bar {\n  display: None;\n}\n\n.rhap_player .rhap_time.rhap_totalTime {\n  display: none !important;\n}\n\n.rhap_time-color {\n  color: white;\n}\n\nbutton.rhap_play-pause-button {\n  font-size: 44px;\n  /* Adjust the font size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
